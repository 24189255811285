import styled from 'styled-components';
import Logo from '../icons/logo';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(239, 241, 245, 0.6);
    backdrop-filter: blur(9px);
`;

export const Label = styled.h1`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    margin-top: 120px;
    letter-spacing: 0.5px;
`;

export const LogoIcon = styled(Logo)`
    width: 44px;
    height: 44px;
`;
