import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
    Card, Table,
} from 'react-rainbow-components';
import { TilePicker } from '@rainbow-modules/dashboard';
import { Trash } from '@rainbow-modules/icons';
import ValueOutput from '../../../../components/value-output';

export const Container = styled.section`
    height: 100%;
    overflow: auto;
`;

export const Content = styled.div`
    display: flex;
    margin: 0 32px 0 36px;
`;

export const CampaignDetailsCard = styled(Card)`
    flex-grow: 1;
    height: fit-content;
`;

export const AnswersCard = styled(Card)`
    margin: 0 32px 0 36px;
    margin-top: 8px;
`;

export const AnswersTotal = styled(ValueOutput)`
    align-items: flex-end;
`;

export const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 32px 0 36px;
`;

export const StatusContainer = styled.div`
    margin: 0 32px 0 36px;
`;

export const StatisticsTitle = styled.h2`
    font-size: 22px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 900;
    font-family: 'Lato Medium', Arial, Helvetica, sans-serif;

    & > svg {
        margin-left: 21px;
        width: 10px;
        height: 6px;
        color: ${(props) => props.theme.rainbow.palette.brand.main};
    }
`;

export const TileContainer = styled(TilePicker)`
    display: flex;
    flex-wrap: wrap;
`;

export const Title = styled.h3`
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 900;
    font-family: 'Lato Black', Arial, Helvetica, sans-serif;
`;

export const CampaignTable = styled(Table)`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    margin: 0 20px;
`;

export const TrashIcon = styled(Trash)`
    width: 60px;
    height: 60px;
    margin: 0 12px 0 0;
    color: ${(props) => props.theme.rainbow.palette.error.main};
`;

export const StyledLink = styled(Link)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;

    :hover,
    :active,
    :visited {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;
