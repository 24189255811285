import styled from 'styled-components';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    font-size: 1rem;
    padding: 0.75rem 0;

    & > strong {
        margin-left: 6px;
    }
`;

export const StatusLabel = styled.span`
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    height: 2rem;
    margin-right: 2rem;
    color: ${(props) => props.color};

    &::before {
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        padding: 0;
        border-radius: 1rem;
        content: '';
        width: 0.875rem;
        height: 0.875rem;
        margin-right: 10px;
        background: ${(props) => props.color};
    }

    &:last-child {
        margin-right: 0;
    }
`;

export const StatusPercentContainer = styled.div.attrs((props) => {
    const { palette } = props.theme.rainbow;
    const backgroundColor = palette.background.highlight;
    const textColor = palette.getContrastText(palette.background.highlight);
    return {
        backgroundColor,
        textColor,
    };
})`
    display: flex;
    margin-left: 0;
    border-top-right-radius: 2.75rem;
    border-bottom-right-radius: 2.75rem;
    box-sizing: border-box;
    width: 100%;
    height: 2.75rem;
    background: ${(props) => props.backgroundColor};
`;

export const StatusPercent = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: -2rem;
    padding-left: 2.5rem;
    padding-right: 0.75rem;
    border-top-right-radius: 2.75rem;
    border-bottom-right-radius: 2.75rem;
    box-sizing: border-box;
    width: calc(${(props) => props.size}% + 2rem);
    min-width: 4.5rem;
    height: 2.75rem;

    &:nth-child(1) {
        width: ${(props) => props.size}%;
        margin-left: 0;
        padding-left: 1rem;
    }

    background: ${(props) => props.colors.backgroundColor};
    color: ${(props) => props.colors.color};
    z-index: ${(props) => props.zIndex};
`;
