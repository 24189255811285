import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
    Column,
    Badge,
    Spinner,
} from 'react-rainbow-components';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import { RecordHeader, RecordPrimaryDetails, RecordField } from '@rainbow-modules/record';
import {
    Container,
    Content,
    Members,
    MembersTable,
    SearchInput,
    Actions,
    StyledContainer,
    SearchContainer,
    StyledButton,
    Icon,
    Arrow,
    StyledUnsubscribes,
    Title,
} from './styled';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import useContactsOnce from '../../../hooks/useContacts';
import { getFirestorePath } from '../../../helpers';
import BackLink from '../../../components/back-link';
import LeftColumn from '../../../components/left-column';

// eslint-disable-next-line react/prop-types
const StatusBadge = ({ value }) => {
    if (value) {
        return <Badge label="unsubscribed" />;
    }
    return null;
};

const GroupDetails = () => {
    const { id } = useParams();
    const location = useLocation();
    const campaignId = location.state && location.state.campaignId;
    const [phoneSearch, setPhoneSearch] = useState();
    const [contacts, isLoadingContacts] = useContactsOnce({
        uid: getCurrentUser().uid,
        groupId: id,
        phone: phoneSearch,
    });

    const [searchInputValue, setSearchInputValue] = useState();

    const [groupDoc, isLoadingGroup] = useDoc({
        path: getFirestorePath(`users/${getCurrentUser().uid}/groups/${id}`),
    });

    if (isLoadingGroup || !groupDoc || !groupDoc.data) {
        return <Spinner />;
    }

    const { members, name, unsubscribes } = groupDoc.data;

    const labelBackTo = campaignId ? 'Back to Campaigns' : 'Back to Groups';
    const backToUrl = campaignId ? `/app/campaigns/${campaignId}` : '/app/groups';

    return (
        <Container>
            <BackLink to={backToUrl} label={labelBackTo} />
            <Content>
                <RecordHeader label="GROUP" description={name}>
                    <RecordPrimaryDetails>
                        <RecordField label="Total Members" value={members || 0} />
                        <RecordField label="Unsubscribes"
                            value={(
                                <StyledUnsubscribes>
                                    {unsubscribes || 0}
                                </StyledUnsubscribes>
                            )} />
                    </RecordPrimaryDetails>
                </RecordHeader>
            </Content>
            <StyledContainer>
                <Title>
                    Members
                </Title>
                <Actions>
                    <SearchContainer>
                        <SearchInput
                            placeholder="Find Member by phone number"
                            iconPosition="right"
                            icon={<Arrow />}
                            value={searchInputValue}
                            onChange={(e) => setSearchInputValue(e.target.value)}
                        />
                        <StyledButton variant="neutral" icon={<Icon />} onClick={() => setPhoneSearch(searchInputValue)} />
                    </SearchContainer>
                </Actions>
            </StyledContainer>
            <Members>
                <MembersTable
                    keyField="id"
                    data={contacts}
                    isLoading={isLoadingContacts}
                    variant="listview">
                    <Column header="Phone number" field="data.phone" component={LeftColumn} width={160} />
                    <Column header="Name" field="data.name" component={LeftColumn} width={300} />
                    <Column header="Tags" field="data.unsubscribe" component={StatusBadge} />
                </MembersTable>
            </Members>
        </Container>
    );
};

export default GroupDetails;
