import firebase from '../firebase';

export function getPercent(percentFor, percentOf) {
    return Math.round((percentFor / percentOf) * 100) || 0;
}

export const getNormalizedBody = (body, stop, stopText) => {
    const rawText = body || '';
    const trimedText = rawText.trim();
    if (stop) {
        return `${trimedText}${stopText}`;
    }
    return trimedText;
};

export const getFunctionName = (baseName) => {
    const prefix = process.env.REACT_APP_MODULE_PREFIX;
    if (prefix) {
        return `${prefix}-${baseName}`;
    }
    return baseName;
};

export const getFirestorePath = (path) => {
    const prefix = process.env.REACT_APP_MODULE_PREFIX;
    if (prefix) {
        return `apps/${prefix}/${path}`;
    }
    return path;
};

// eslint-disable-next-line no-bitwise
export const getHash = (string) => string.split('').reduce((hash, char, index) => (((hash << 5) - hash) + string.charCodeAt(index)) & 0xFFFFFFFF, '');

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const URL = window.URL || window.webkitURL;

export const createBlob = (data, contentType) => {
    const blob = new Blob(['\uFEFF', data], { type: contentType });
    return URL.createObjectURL(blob);
};

const downloadFileXHR = (url) => {
    return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = () => {
            return resolve(URL.createObjectURL(xhr.response));
        };
        xhr.open('GET', url);
        xhr.send();
    });
};

export const createDownloadLink = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};

export const downloadFile = async (filePath) => {
    const remoteUrl = await firebase.storage().ref(filePath).getDownloadURL();
    const localBlobUrl = await downloadFileXHR(remoteUrl);
    return localBlobUrl;
};

export const round = (number, precision) => {
    const factor = 10 ** precision;
    return Math.round(number * factor) / factor;
};
