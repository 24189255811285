import firebase from '../../../firebase';
import { getFirestorePath, getFunctionName, downloadFile } from '../../../helpers';

const generateCampaignReport = firebase.functions().httpsCallable(getFunctionName('generateCampaignReport'));

const requestCampaignReportUri = async (fileName, data) => {
    const { uid, ...rest } = data;
    const filePath = getFirestorePath(`${uid}/reports/${fileName}`);

    try {
        const localBlobUrl = await downloadFile(filePath);
        return localBlobUrl;
    } catch (err) {
        if (err.code === 'storage/object-not-found') {
            const result = await generateCampaignReport({
                ...rest,
                fileName: filePath,
            });
            if (result.data) {
                const localBlobUrl = await downloadFile(filePath);
                return localBlobUrl;
            }
        }
        throw Error('Unable to download report');
    }
};

export default requestCampaignReportUri;
