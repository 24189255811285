import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { getPercent } from '../../../../helpers';
import {
    Container,
    Header,
    StatusLabel,
    StatusPercentContainer,
    StatusPercent,
} from './styled';

export default function SMSStatus(props) {
    // eslint-disable-next-line object-curly-newline
    const { className, total, delivered, bounced, queued, optedOut, colors } = props;

    const percentDelivered = getPercent(delivered, total);
    const percentQueued = getPercent(queued, total);
    const percentBounced = getPercent(bounced, total);
    const percentOptedOut = getPercent(optedOut, total);

    return (
        <Container className={className}>
            <Header>
                Total Send:
                <strong>{total}</strong>
            </Header>
            <div>
                <StatusLabel color={colors.delivered.backgroundColor}>Delivered</StatusLabel>
                <StatusLabel color={colors.queued.backgroundColor}>Queued</StatusLabel>
                <StatusLabel color={colors.bounced.backgroundColor}>Bounced</StatusLabel>
                <StatusLabel color={colors['opted out'].backgroundColor}>Opted Out</StatusLabel>
            </div>
            <StatusPercentContainer>
                <RenderIf isTrue={percentDelivered > 0}>
                    <StatusPercent zIndex={3} colors={colors.delivered} size={percentDelivered}>{`${percentDelivered}%`}</StatusPercent>
                </RenderIf>
                <RenderIf isTrue={percentQueued > 0}>
                    <StatusPercent zIndex={2} colors={colors.queued} size={percentQueued}>{`${percentQueued}%`}</StatusPercent>
                </RenderIf>
                <RenderIf isTrue={percentBounced > 0}>
                    <StatusPercent zIndex={1} colors={colors.bounced} size={percentBounced}>{`${percentBounced}%`}</StatusPercent>
                </RenderIf>
                <RenderIf isTrue={percentOptedOut > 0}>
                    <StatusPercent colors={colors['opted out']} size={percentOptedOut}>{`${percentOptedOut}%`}</StatusPercent>
                </RenderIf>
            </StatusPercentContainer>
        </Container>
    );
}

SMSStatus.propTypes = {
    className: PropTypes.string,
    total: PropTypes.number,
    delivered: PropTypes.number,
    bounced: PropTypes.number,
    queued: PropTypes.number,
    optedOut: PropTypes.number,
    colors: PropTypes.shape({
        'opted out': PropTypes.shape({
            backgroundColor: PropTypes.string,
            color: PropTypes.string,
        }),
        delivered: PropTypes.shape({
            backgroundColor: PropTypes.string,
            color: PropTypes.string,
        }),
        bounced: PropTypes.shape({
            backgroundColor: PropTypes.string,
            color: PropTypes.string,
        }),
        queued: PropTypes.shape({
            backgroundColor: PropTypes.string,
            color: PropTypes.string,
        }),
    }),
};

SMSStatus.defaultProps = {
    className: undefined,
    delivered: 0,
    bounced: 0,
    queued: 0,
    optedOut: 0,
    total: 0,
    colors: {
        'opted out': { backgroundColor: '#f2707a', color: '#fff' },
        delivered: { backgroundColor: '#9DC35E', color: '#fff' },
        queued: { backgroundColor: '#F4D879', color: '#fff' },
        bounced: { backgroundColor: '#F7A753', color: '#fff' },
    },
};
