import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Column } from 'react-rainbow-components';
import LeftColumn from '../../../components/left-column';
import FormatedDateCol from '../../../components/formatted-date-col';
import { StyledPrice, StyledCommissionPrice, TransactionsTable } from './styled';

// eslint-disable-next-line react/prop-types
const CommissionColumn = ({ value }) => <StyledCommissionPrice value={value} size="x-small" />;

// eslint-disable-next-line react/prop-types
const PriceColumn = ({ value }) => <StyledPrice value={value} size="x-small" />;

const sortData = (data, field, sortDirection) => {
    const newData = [...data];
    const key = (value) => value[field];
    const reverse = sortDirection === 'asc' ? 1 : -1;

    const newSortedData = newData.sort((aItem, bItem) => {
        const aValue = key(aItem) || '';
        const bValue = key(bItem) || '';
        return reverse * ((aValue > bValue) - (bValue > aValue));
    });

    return newSortedData;
};
export default function TransactionsList(props) {
    const { isLoading, data, showCommissionColumn } = props;
    const [sortedBy, setSortedBy] = useState();
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortedData, setSortedData] = useState(data);

    useEffect(() => {
        const newData = sortedBy
            ? sortData(data, sortedBy, sortDirection)
            : data;
        setSortedData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleOnSort = (_, field, nextSortDirection) => {
        setSortedData(sortData(sortedData, field, nextSortDirection));
        setSortDirection(nextSortDirection);
        setSortedBy(field);
    };

    if (showCommissionColumn) {
        return (
            <TransactionsTable
                isLoading={isLoading}
                data={sortedData}
                variant="listview"
                keyField="id"
                onSort={handleOnSort}
                sortDirection={sortDirection}
                sortedBy={sortedBy}
            >
                <Column
                    header="Date"
                    field="createdAt"
                    width={200}
                    component={FormatedDateCol}
                    sortable
                />
                <Column header="Description" field="description" component={LeftColumn} />
                <Column
                    header="Commission"
                    field="commission"
                    width={150}
                    component={CommissionColumn}
                    sortable
                />
                <Column
                    header="Amount"
                    field="amount"
                    width={150}
                    component={PriceColumn}
                    sortable
                />
            </TransactionsTable>
        );
    }

    return (
        <TransactionsTable
            isLoading={isLoading}
            data={sortedData}
            variant="listview"
            keyField="id"
            onSort={handleOnSort}
            sortDirection={sortDirection}
            sortedBy={sortedBy}
        >
            <Column
                header="Date"
                field="createdAt"
                width={200}
                component={FormatedDateCol}
                sortable
            />
            <Column header="Description" field="description" component={LeftColumn} />
            <Column
                header="Amount"
                field="amount"
                width={150}
                component={PriceColumn}
                sortable
            />
        </TransactionsTable>
    );
}

TransactionsList.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.array,
    showCommissionColumn: PropTypes.bool,
};

TransactionsList.defaultProps = {
    isLoading: false,
    data: [],
    showCommissionColumn: false,
};
