import React from 'react';
import { createPortal } from 'react-dom';
import { Spinner } from 'react-rainbow-components';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Logo from '../icons/logo';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 10000000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

const Text = styled.h3`
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 999;
    margin-top: 120px;
    font-weight: 900;
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

const Backdrop = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(239, 241, 245, 0.6);
    backdrop-filter: blur(3px);
`;

const LogoIcon = styled(Logo)`
    width: 40px;
    height: 40px;
`;

const GlobalSpinner = () => {
    const [isLoading, message] = useSelector((state) => [
        state.app.get('isGlobalLoading'),
        state.app.get('globalLoadingMessage'),
    ]);
    if (isLoading) {
        return createPortal((
            <Container>
                <Backdrop />
                <Spinner type="arc" variant="brand" size="x-large">
                    <LogoIcon />
                </Spinner>
                <Text>{message}</Text>
            </Container>
        ), document.body);
    }
    return null;
};

export const showSpinner = ({ message }) => ({
    type: 'APP_SHOW_GLOBAL_LOADING',
    message,
});

export const hideSpinner = () => ({
    type: 'APP_HIDE_GLOBAL_LOADING',
});

export default GlobalSpinner;
