import styled from 'styled-components';
import ErrorCircleIcon from '../icons/errorCircle';

export const CreditContainer = styled.div`
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Label = styled.h2`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-transform: uppercase;
    margin: 3px 4px 0 0;
    ${(props) => props.size === 'small'
        && `
            font-size: 10px;
        `};
`;

export const ValueContainer = styled.div`
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
`;

export const Currency = styled.h3`
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    margin-right: 4px;
    margin-top: 2px;
    ${(props) => props.size === 'small'
        && `
            font-size: 16px;
            line-height: 16px;
        `};
    ${(props) => props.size === 'x-small'
        && `
            font-size: 14px;
            line-height: 14px;
        `};
`;

export const ValueLarge = styled.h1`
    font-size: 32px;
    font-weight: 900;
    line-height: 1;
    ${(props) => props.size === 'small'
        && `
            font-size: 24px;
    `};
    ${(props) => props.size === 'x-small'
        && `
            font-size: 18px;
    `};
`;

export const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ErrorIcon = styled(ErrorCircleIcon)`
    width: 14px;
    height: 14px;
    margin-right: 4px;
`;

export const ErrorText = styled.h2`
    font-size: 14px;
    color: #ea4243;
`;
