import React, { useState } from 'react';
import propTypes from 'prop-types';
import { parsePhoneNumber } from 'libphonenumber-js';
import { ButtonIcon, ImportRecordsFlow } from 'react-rainbow-components';
import withReduxForm from 'react-rainbow-components/libs/hocs/withReduxForm';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import ErrorText from 'react-rainbow-components/components/Input/styled/errorText';
import UploadIcon from '../../../components/icons/upload';
import CheckmarkCircleIcon from '../../../components/icons/checkmarkCircle';
import {
    ImportContainer,
    ImportLabel,
    ImportContent,
    ImportText,
    ImportIcon,
    LeftContent,
    ImportIconLarge,
    ImportedContent,
    ImportDescription,
    ImportButton,
    ImportHelpText,
    StatusContainer,
    StatusIcon,
    StatusText,
} from './styled';
import TrashIcon from '../../../components/icons/trash';

const schema = {
    collection: 'groups',
    attributes: {
        name: {
            type: String,
        },
        phone: {
            type: String,
            required: true,
        },
    },
};

const isValidUSPhone = (phone) => {
    const phoneNumber = parsePhoneNumber(phone, 'US');
    return phoneNumber.isValid() && phoneNumber.country === 'US';
};

const ImportDataInput = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [metadata, setMetadata] = useState({ success: 0, fail: 0 });
    const {
        value,
        onChange,
        onFocus,
        onBlur,
        error,
    } = props;
    const onComplete = (contacts) => {
        const meta = { success: 0, fail: 0 };
        if (contacts.data.length > 0) {
            // eslint-disable-next-line no-param-reassign
            contacts.data = contacts.data.reduce((seed, value) => {
                const phone = String(value.phone);
                if (isValidUSPhone(phone, 'US')) {
                    meta.success += 1;
                    seed.push({
                        ...value,
                        phone: parsePhoneNumber(phone, 'US').number,
                    });
                } else {
                    meta.fail += 1;
                }
                return seed;
            }, []);
            setMetadata(meta);
            onChange({
                ...contacts,
                meta,
            });
        }
        setIsOpen(false);
    };

    return (
        <ImportContainer>
            <ImportLabel>
                Contacts
            </ImportLabel>
            <RenderIf isTrue={!value}>
                <ImportContent>
                    <LeftContent>
                        <ImportIcon />
                        <ImportText>
                            Your CSV File
                        </ImportText>
                    </LeftContent>
                    <ImportButton onClick={() => setIsOpen(true)} onFocus={onFocus} onBlur={onBlur}>
                        <UploadIcon className="rainbow-m-right_xx-small" />
                        Import
                    </ImportButton>
                </ImportContent>
            </RenderIf>

            <RenderIf isTrue={value}>
                <ImportedContent>
                    <LeftContent>
                        <ImportIconLarge />
                        <div>
                            <ImportText>
                                {`Total: ${metadata.success + metadata.fail} contacts`}
                            </ImportText>
                            <ImportDescription>
                                <StatusContainer>
                                    <StatusIcon as={CheckmarkCircleIcon} />
                                    <StatusText>
                                        {`${metadata.success} Successful`}
                                    </StatusText>
                                </StatusContainer>
                                <StatusContainer>
                                    <StatusIcon />
                                    <StatusText>
                                        {`${metadata.fail} Errors`}
                                    </StatusText>
                                </StatusContainer>
                            </ImportDescription>
                        </div>
                    </LeftContent>
                    <ButtonIcon icon={<TrashIcon />} onClick={() => onChange(undefined)} />
                </ImportedContent>
            </RenderIf>
            <ImportHelpText>
                <h5>
                    Check if your phone numbers have a valid format
                </h5>
                <h5>
                    (e.g., +1-212-718-1234 or 1-212-718-1234)
                </h5>
            </ImportHelpText>
            <RenderIf isTrue={!!error}>
                <ErrorText alignSelf="center">
                    {error}
                </ErrorText>
            </RenderIf>
            <ImportRecordsFlow
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                schema={schema}
                onComplete={onComplete}
                actionType="add-records"
            />
        </ImportContainer>
    );
};

ImportDataInput.propTypes = {
    value: propTypes.object,
    onChange: propTypes.func,
    onFocus: propTypes.func,
    onBlur: propTypes.func,
    error: propTypes.string,
};

ImportDataInput.defaultProps = {
    value: undefined,
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    error: undefined,
};

export default withReduxForm(ImportDataInput);
