const propToStatusMap = {
    queued: ['accepted', 'queued', 'sending'],
    sent: ['sent'],
    delivered: ['delivered'],
    bounced: ['undelivered', 'failed'],
};

const getStatusFromLabel = (label) => propToStatusMap[label];

module.exports = getStatusFromLabel;
