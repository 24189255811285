import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ButtonGroup, Spinner } from 'react-rainbow-components';
import { showAppNotification } from '@rainbow-modules/app';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import { Download } from '@rainbow-modules/icons';
import { Tile } from '@rainbow-modules/dashboard';
import { RecordHeader, RecordPrimaryDetails, RecordField } from '@rainbow-modules/record';
import { showSpinner, hideSpinner } from '../../../components/global-spinner';
import TextOutput from '../../../components/text-output';
import BackLink from '../../../components/back-link';
import ButtonIconTooltip from '../../../components/button-icon-tooltip';
import SMSStatus from './sms-status';
import CampaignMessages from './campaignMessages';
import { showErrorMessage } from '../../../redux/actions/app';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import {
    Container,
    Content,
    StyledContainer,
    StatisticsTitle,
    TileContainer,
    Title,
    StatusContainer,
    StyledLink,
} from './styled';
import { getFirestorePath, getHash, createDownloadLink } from '../../../helpers';
import useCurrentUser from '../../../hooks/useCurrentUser';
import getStatusFromLabel from './helpers/getStatusFromLabel';
import getCampaignReportUri from './getCampaignReportUri';

const statusColors = {
    'opted out': { backgroundColor: '#f2707a', color: '#fff' },
    delivered: { backgroundColor: '#9DC35E', color: '#fff' },
    queued: { backgroundColor: '#F4D879', color: '#fff' },
    bounced: { backgroundColor: '#F7A753', color: '#fff' },
    sent: { backgroundColor: '#cad5d5', color: '#fff' },
};

// eslint-disable-next-line react/prop-types
const GroupLink = ({ value, url, campaignId }) => {
    return (
        <StyledLink
            to={{
                pathname: url,
                state: { campaignId },
            }}
        >
            {value}
        </StyledLink>
    );
};

const getHoursBetweenDates = (date1, date2) => Math.floor(Math.abs(date1 - date2) / 3600000);

const getReportFileName = (campaignId, filters) => {
    const filtersStr = (filters.sort((filter1, filter2) => filter1 > filter2).join(','));
    const hash = getHash(`${campaignId}__[${filtersStr}]`);
    return `campaign-report-${hash}.csv`;
};

export default function CampaignDetails({ className }) {
    const { id } = useParams();
    const { uid } = useCurrentUser();
    const dispatch = useDispatch();
    const [activeFilters, setActiveFilters] = useState([]);
    const [campaignDoc, isLoading] = useDoc({
        path: getFirestorePath(`users/${getCurrentUser().uid}/campaigns/${id}`),
    });

    if (isLoading || !campaignDoc) {
        return <Spinner />;
    }

    const campaign = campaignDoc.data;
    const {
        name,
        createdAt,
        body,
        cost,
        sent,
        delivered,
        undelivered,
        failed,
        group: {
            id: groupId,
            members,
            name: groupName = 'unknown',
        },
    } = campaign;

    const smsSend = members;
    const finalDelivered = delivered || 0;
    const finalUndelivered = undelivered || 0;
    const finalUnsent = failed || 0;
    const finalSent = sent || 0;
    const finalFailed = finalUnsent + finalUndelivered;
    const finalQueued = smsSend - (finalSent + finalUnsent);

    const disableReportDownload = getHoursBetweenDates(createdAt.toDate(), new Date()) < 24;

    const downloadCampaignReport = async () => {
        dispatch(showSpinner({
            message: 'Downloading campaign report',
        }));
        try {
            const statuses = activeFilters.reduce((result, filter) => [
                ...result,
                ...getStatusFromLabel(filter),
            ], []);
            const url = await getCampaignReportUri(
                getReportFileName(id, activeFilters),
                {
                    uid,
                    campaignId: id,
                    filters: statuses.length > 0
                        ? [{ key: 'status', value: [...statuses] }]
                        : [],
                },
            );
            createDownloadLink(url, `campaign-report_${Date.now()}.csv`);

            showAppNotification({
                title: 'Campaign Report Downloaded Successfully!',
                description: `The report for campaign "${name}" was downloaded successfully.`,
                icon: 'success',
                timeout: 5000,
            });
        } catch (err) {
            dispatch(showErrorMessage({
                title: 'Campaign Report Download Failed!',
                message: `There was an error while downloading campaign "${name}" report`,
            }));
        }
        dispatch(hideSpinner());
    };

    return (
        <Container className={className}>
            <BackLink to="/app/campaigns" label="Back to Campaigns" />
            <Content>
                <RecordHeader label="CAMPAIGNS" description={name}>
                    <RecordPrimaryDetails>
                        <RecordField label="Date & Time" value={createdAt.toDate()} type="dateTime" />
                        <RecordField label="Contacts" value={members || 0} />
                        <RecordField
                            label="To"
                            type="url"
                            value={groupName}
                            component={GroupLink}
                            url={`/app/groups/${groupId}`}
                            campaignId={id}
                        />
                        <RecordField label="SMS Send" value={smsSend} type="number" />
                        <RecordField label="Cost" value={cost} type="currency" />
                    </RecordPrimaryDetails>
                    <TextOutput
                        className="rainbow-m-top_large"
                        label="Message"
                        value={body} />
                </RecordHeader>
            </Content>
            <StyledContainer>
                <StatisticsTitle>Delivery Statistics</StatisticsTitle>
                <ButtonGroup className="rainbow-m-vertical_medium" variant="shaded">
                    <ButtonIconTooltip
                        variant="border-filled"
                        shaded
                        className="rainbow-m-left_medium"
                        icon={<Download />}
                        text="Download Report"
                        disabled={disableReportDownload}
                        onClick={downloadCampaignReport}
                    />
                </ButtonGroup>
            </StyledContainer>
            <StatusContainer>
                <Title>SMS Status</Title>
                <SMSStatus
                    total={smsSend}
                    delivered={finalDelivered}
                    queued={finalQueued}
                    bounced={finalFailed}
                    optedOut={0}
                    colors={statusColors}
                />
            </StatusContainer>
            <StyledContainer>
                <Title>Members</Title>
                <TileContainer multiple value={activeFilters} onChange={setActiveFilters}>
                    <Tile
                        name="sent"
                        label="Sent"
                        value={finalSent}
                        variant="badge"
                        backgroundColor="#cad5d5"
                        color="#fff"
                        className="rainbow-m-right_medium"
                    />
                    <Tile
                        name="delivered"
                        label="Delivered"
                        value={finalDelivered}
                        variant="badge"
                        backgroundColor="#9DC35E"
                        color="#fff"
                        className="rainbow-m-right_medium"
                    />
                    <Tile
                        name="bounced"
                        label="Bounced"
                        value={finalFailed}
                        variant="badge"
                        backgroundColor="#F7A753"
                        color="#fff"
                        className="rainbow-m-right_medium"
                    />
                    <Tile
                        name="queued"
                        label="Queued"
                        value={finalQueued}
                        variant="badge"
                        backgroundColor="#F4D879"
                        color="#fff"
                        className="rainbow-m-right_medium"
                    />
                    <Tile
                        name="opted-out"
                        label="Opted Out"
                        value={0}
                        variant="badge"
                        backgroundColor="#F2707A"
                        color="#fff"
                    />
                </TileContainer>
            </StyledContainer>
            <CampaignMessages
                campaignId={id}
                statusColors={statusColors}
                filters={activeFilters}
            />
        </Container>
    );
}

CampaignDetails.propTypes = {
    className: PropTypes.string,
};

CampaignDetails.defaultProps = {
    className: undefined,
};
