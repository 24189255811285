import React from 'react';
import { isEmpty } from '@rainbow-modules/validation';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import empty from './empty.svg';
import {
    Content,
    ChartsCard,
    Title,
    ChartContainer,
    BarChart,
    EmptyMessage,
    MessageText,
    EmptyImage,
    MessageTitle,
} from './styled';
import { getFirestorePath } from '../../../helpers';

const LAST_DAYS = 15;

function getDaysBackFromNow(days) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setDate(today.getDate() - days + 1);
    return today;
}
function getDaysFromNow(days) {
    const today = new Date();
    today.setDate(today.getDate() + days);
    return today;
}

function getFormattedDate(date) {
    const year = date.getFullYear();
    const dateMonth = date.getMonth() + 1;
    const month = dateMonth < 10 ? `0${dateMonth}` : dateMonth;
    const dateDay = date.getDate();
    const day = dateDay < 10 ? `0${dateDay}` : dateDay;
    return `${year}-${month}-${day}`;
}

function hasData(chartData) {
    return chartData.some((item) => !isEmpty(item.values));
}

export default function Chart() {
    const fromDate = getDaysBackFromNow(LAST_DAYS);
    const toDate = getDaysFromNow(1);
    const [history] = useCollection({
        path: getFirestorePath(`users/${getCurrentUser().uid}/history`),
        query: (ref) => ref.where('createdAt', '<=', toDate).where('createdAt', '>=', fromDate),
    });

    const chartData = Array.from(Array(LAST_DAYS + 1), (_, index) => {
        const tmp = new Date(fromDate);
        const id = getFormattedDate(new Date(tmp.setDate(tmp.getDate() + index)));
        const parts = id.split('-');
        return {
            id,
            label: `${parts[1]}/${parts[2]}`,
            values: [],
        };
    });

    history.forEach((item) => {
        const index = chartData.findIndex((dataItem) => dataItem.id === item.id);
        if (index !== -1) {
            chartData[index].values = [item.data.delivered, item.data.sent];
        }
    });

    const showChart = hasData(chartData);

    return (
        <ChartsCard>
            <Title>Outgoing Messages</Title>
            <Content>
                <RenderIf isTrue={showChart}>
                    <ChartContainer>
                        <BarChart
                            data={chartData}
                            titles={['SMS Delivered', 'SMS Sent']}
                            yLabel="SMS"
                        />
                    </ChartContainer>
                </RenderIf>
                <RenderIf isTrue={!showChart}>
                    <EmptyMessage>
                        <EmptyImage src={empty} alt="Empty Message" />
                        <MessageTitle>
                            Uh-oh!
                        </MessageTitle>
                        <MessageText>
                            We did not find any data to show...
                        </MessageText>
                    </EmptyMessage>
                </RenderIf>
            </Content>
        </ChartsCard>
    );
}
