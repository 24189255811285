import styled from 'styled-components';
import { ButtonIcon } from 'react-rainbow-components';
import CampaignIcon from '../../icons/campaign';
import Logo from '../../icons/logo';

export const StyledSection = styled.section`
    background-color: white;
    width: 88px;
    border-radius: 20px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    padding: 20px 0;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 100;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    background-color: rgba(254, 72, 73, 0.1);
`;

export const SidebarIcon = styled(CampaignIcon)`
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const LogoIcon = styled(Logo)`
    width: 56px;
    height: 56px;
    align-self: center;
    margin-bottom: 40px;
`;
