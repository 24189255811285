import React from 'react';
import propTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { Container } from './styled';

const FormattedDateCol = ({ value }) => (
    <Container>
        <FormattedDate
            value={value.toDate()}
            year="numeric"
            month="numeric"
            day="numeric"
            hour="numeric"
            minute="numeric"
        />
    </Container>
);

FormattedDateCol.propTypes = {
    value: propTypes.object.isRequired,
};

export default FormattedDateCol;
