import { useState, useEffect } from 'react';
import { getContacts } from '../redux/services/firebase';

export default function useContactsOnce(props) {
    const {
        uid, groupId, phone,
    } = props;
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const queryLimit = (ref) => ref.limit(100);
        const queryOrder = (ref) => ref.orderBy('name', 'asc');
        const queryPhone = phone ? ((ref) => ref.where('phone', '==', phone)) : (ref) => ref;
        const finalQuery = (ref) => queryLimit(queryOrder(queryPhone(ref)));
        getContacts(uid, groupId, finalQuery).then((contactsData) => {
            setData(contactsData);
            setIsLoading(false);
        }).catch(() => {
            setData([]);
            setIsLoading(false);
        });
    }, [uid, groupId, phone]);

    return [data, isLoading];
}
