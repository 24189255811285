import React from 'react';
import PropTypes from 'prop-types';
import {
    Redirect, Route, Switch, useLocation, useHistory,
} from 'react-router-dom';
import { AppSidebar, TopBar } from '../../components';
import Campaigns from './campaigns';
import CampaignDetails from './campaignDetails';
import Groups from './groups';
import Profile from './profile';
import Dashboard from './dashboard';
import GroupDetails from './groupDetails';
import Transactions from './transactions';
import GlobalSpinner from '../../components/global-spinner';
import ErrorBoundary from '../../components/error-boundary';
import { StyledContent, StyledAppContainer } from './styled';

export default function AuthRoutes(props) {
    const {
        className,
        style,
    } = props;
    const location = useLocation();
    const history = useHistory();

    return (
        <ErrorBoundary history={history}>
            <StyledAppContainer className={className} style={style}>
                <AppSidebar location={location} />
                <StyledContent>
                    <TopBar />
                    <Switch>
                        <Route path="/app/dashboard" component={Dashboard} />
                        <Route exact path="/app/groups" component={Groups} />
                        <Route path="/app/groups/:id" component={GroupDetails} />
                        <Route exact path="/app/campaigns" component={Campaigns} />
                        <Route path="/app/campaigns/:id" component={CampaignDetails} />
                        <Route path="/app/profile" component={Profile} />
                        <Route path="/app/transactions" component={Transactions} />
                        <Redirect to="/app/dashboard" />
                    </Switch>
                </StyledContent>
                <GlobalSpinner />
            </StyledAppContainer>
        </ErrorBoundary>
    );
}

AuthRoutes.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

AuthRoutes.defaultProps = {
    className: undefined,
    style: undefined,
};
