import styled from 'styled-components';
import {
    Table,
    Input,
    ButtonIcon,
} from 'react-rainbow-components';
import ArrowRight from '../../../../components/icons/arrowRight';
import Magnifying from '../../../../components/icons/magnifying';

export const Container = styled.section`
    height: 100%;
    overflow: auto;
`;

export const Content = styled.div`
    display: flex;
    margin: 0 32px 0 36px;
`;

export const Members = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    margin: 0 20px;
`;

export const Title = styled.h1`
    font-size: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 32px 0 36px;
`;

export const MembersTable = styled(Table)`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
`;

export const SearchInput = styled(Input)`
    width: 320px;

    input {
        border: 1px solid transparent;
        box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_10};
    }
`;

export const IconContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background-color: ${(props) => props.theme.rainbow.palette.background.disabled};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Actions = styled.div`
    display: flex;
`;

export const SearchContainer = styled.div`
    display: flex;
    position: relative;
`;

export const StyledButton = styled(ButtonIcon)`
    right: 2px;
    top: 2px;
    position: absolute;
    height: 36px;
    width: 36px;
    background: #f0f4fa;
    border: 1px solid transparent;
`;

export const Icon = styled(Magnifying)`
    width: 24px;
    height: 24px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const Arrow = styled(ArrowRight)`
    color: transparent;
`;

export const StyledUnsubscribes = styled.span`
    color: ${(props) => props.theme.rainbow.palette.error.main};
`;
