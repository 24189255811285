import React from 'react';
import PropTypes from 'prop-types';

export default function Dashboard(props) {
    const { className } = props;
    return (
        <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="dashboard" fill="currentColor" fillRule="nonzero">
                    <path d="M9.24993895,8.00006105 L1.74993895,8.00006105 C0.784973156,8.00006105 4.52970994e-14,7.21508789 4.52970994e-14,6.24993895 L4.52970994e-14,1.74993895 C4.52970994e-14,0.784973156 0.784973156,4.52970994e-14 1.74993895,4.52970994e-14 L9.24993895,4.52970994e-14 C10.2150879,4.52970994e-14 11.000061,0.784973156 11.000061,1.74993895 L11.000061,6.24993895 C11.000061,7.21508789 10.2150879,8.00006105 9.24993895,8.00006105 Z M1.74993895,1.5 C1.61206055,1.5 1.5,1.61206055 1.5,1.74993895 L1.5,6.24993895 C1.5,6.3880005 1.61206055,6.50006105 1.74993895,6.50006105 L9.24993895,6.50006105 C9.3880005,6.50006105 9.50006105,6.3880005 9.50006105,6.24993895 L9.50006105,1.74993895 C9.50006105,1.61206055 9.3880005,1.5 9.24993895,1.5 L1.74993895,1.5 Z" id="Shape-Copy" />
                    <path d="M9.24993895,24 L1.74993895,24 C0.784973156,24 4.52970994e-14,23.2150268 4.52970994e-14,22.250061 L4.52970994e-14,11.750061 C4.52970994e-14,10.7849121 0.784973156,9.99993895 1.74993895,9.99993895 L9.24993895,9.99993895 C10.2150879,9.99993895 11.000061,10.7849121 11.000061,11.750061 L11.000061,22.250061 C11.000061,23.2150268 10.2150879,24 9.24993895,24 Z M1.74993895,11.499939 C1.61206055,11.499939 1.5,11.6119995 1.5,11.750061 L1.5,22.250061 C1.5,22.3879395 1.61206055,22.5 1.74993895,22.5 L9.24993895,22.5 C9.3880005,22.5 9.50006105,22.3879395 9.50006105,22.250061 L9.50006105,11.750061 C9.50006105,11.6119995 9.3880005,11.499939 9.24993895,11.499939 L1.74993895,11.499939 Z" id="Shape-Copy-2" />
                    <path d="M22.250061,24 L14.750061,24 C13.7849121,24 12.999939,23.2150268 12.999939,22.250061 L12.999939,17.750061 C12.999939,16.7849121 13.7849121,15.999939 14.750061,15.999939 L22.250061,15.999939 C23.2150268,15.999939 24,16.7849121 24,17.750061 L24,22.250061 C24,23.2150268 23.2150268,24 22.250061,24 L22.250061,24 Z M14.750061,17.499939 C14.6119995,17.499939 14.499939,17.6119995 14.499939,17.750061 L14.499939,22.250061 C14.499939,22.3879395 14.6119995,22.5 14.750061,22.5 L22.250061,22.5 C22.3879395,22.5 22.5,22.3879395 22.5,22.250061 L22.5,17.750061 C22.5,17.6119995 22.3879395,17.499939 22.250061,17.499939 L14.750061,17.499939 Z" id="Shape-Copy-3" />
                    <path d="M22.250061,14.000061 L14.750061,14.000061 C13.7849121,14.000061 12.999939,13.2150879 12.999939,12.249939 L12.999939,1.74993895 C12.999939,0.784973156 13.7849121,4.70734562e-14 14.750061,4.70734562e-14 L22.250061,4.70734562e-14 C23.2150268,4.70734562e-14 24,0.784973156 24,1.74993895 L24,12.249939 C24,13.2150879 23.2150268,14.000061 22.250061,14.000061 L22.250061,14.000061 Z M14.750061,1.5 C14.6119995,1.5 14.499939,1.61206055 14.499939,1.74993895 L14.499939,12.249939 C14.499939,12.3880005 14.6119995,12.500061 14.750061,12.500061 L22.250061,12.500061 C22.3879395,12.500061 22.5,12.3880005 22.5,12.249939 L22.5,1.74993895 C22.5,1.61206055 22.3879395,1.5 22.250061,1.5 L14.750061,1.5 Z" id="Shape-Copy-4" />
                </g>
            </g>
        </svg>
    );
}

Dashboard.propTypes = {
    className: PropTypes.string,
};
Dashboard.defaultProps = {
    className: undefined,
};
