import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import { showAppNotification } from '@rainbow-modules/app';
import { Tile, TilePicker } from '@rainbow-modules/dashboard';
import { Download } from '@rainbow-modules/icons';
import {
    Container,
    Header,
    Actions,
    StyledBadgePrice,
    Title,
} from './styled';
import TransactionsList from './transactionsList';
import ButtonIconTooltip from '../../../components/button-icon-tooltip';
import { showSpinner, hideSpinner } from '../../../components/global-spinner';
import { showErrorMessage } from '../../../redux/actions/app';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import useCurrentUser from '../../../hooks/useCurrentUser';
import {
    getFirestorePath,
    getHash,
    createDownloadLink,
    round,
} from '../../../helpers';
import getReportUri from './getReportUri';

const MAX_TRANSACTIONS = 50;

const shouldFilterList = (filters) => filters.length > 0 && filters.length < 5;

const query = (refs) => refs.orderBy('createdAt', 'desc').limit(MAX_TRANSACTIONS);

const getReportFileName = (id, filters) => {
    const filtersStr = (filters.length === 1) ? filters[0] : '';
    const hash = getHash(`${id}__[${filtersStr}]`);
    return `transactions-report-${hash}.csv`;
};

const getLatestTransactionTimestamp = (transactionsList) => {
    const { createdAt } = transactionsList[0];
    return createdAt.toMillis();
};

const checkResellerRole = (roles) => {
    return roles && roles.some((role) => /reseller\d+/i.test(role));
};

export default function Transactions({ className, style }) {
    const { uid } = useCurrentUser();
    const dispatch = useDispatch();
    const [filterBy, setFilterBy] = useState([]);
    const [isReseller, setIsReseller] = useState(false);

    useEffect(() => {
        getCurrentUser().getIdTokenResult(true).then((idTokenResult) => {
            setIsReseller(checkResellerRole(idTokenResult.claims.roles));
        });
    }, []);

    const [transactions, isLoading] = useCollection({
        path: getFirestorePath(`users/${getCurrentUser().uid}/transactions`),
        query,
        flat: true,
    });

    const filteredList = shouldFilterList(filterBy)
        ? transactions.filter((item) => filterBy.includes(item.type))
        : transactions;

    const totalIncomes = round(transactions.reduce((total, item) => {
        if (item.type === 'income') return total + item.amount;
        return total;
    }, 0), 2);

    const totalExpenses = round(transactions.reduce((total, item) => {
        if (item.type === 'expense') return total + item.amount;
        return total;
    }, 0), 2);

    const disableReportDownload = filteredList.length === 0;

    const downloadTransactionsReport = async () => {
        dispatch(showSpinner({
            message: 'Downloading transactions report',
        }));
        try {
            const url = await getReportUri(
                getReportFileName(
                    getLatestTransactionTimestamp(filteredList),
                    filterBy,
                ),
                {
                    uid,
                    filters: filterBy.length > 0
                        ? [{ key: 'type', value: filterBy }]
                        : [],
                },
            );
            createDownloadLink(url, `transactions-report_${Date.now()}.csv`);

            showAppNotification({
                title: 'Transactions Report Downloaded Successfully!',
                description: 'Your transactions report was downloaded successfully.',
                icon: 'success',
                timeout: 5000,
            });
        } catch (err) {
            dispatch(showErrorMessage({
                title: 'Transactions Report Download Failed!',
                message: 'There was an error while downloading the Transactions report',
            }));
        }
        dispatch(hideSpinner());
    };

    return (
        <Container className={className} style={style}>
            <Header>
                <Title>Last 50 Transactions</Title>
                <Actions>
                    <TilePicker multiple value={filterBy} onChange={setFilterBy}>
                        <Tile
                            name="expense"
                            label="Expense"
                            value={<StyledBadgePrice value={totalExpenses.toFixed(2)} size="x-small" />}
                            variant="badge"
                            backgroundColor="#f2707a"
                            color="#fff"
                        />
                        <Tile
                            name="income"
                            label="Income"
                            value={<StyledBadgePrice value={totalIncomes.toFixed(2)} size="x-small" />}
                            variant="badge"
                            backgroundColor="#98d38c"
                            color="#fff"
                        />
                    </TilePicker>
                    <ButtonIconTooltip
                        variant="border-filled"
                        shaded
                        className="rainbow-m-left_medium"
                        icon={<Download />}
                        text="Download Report"
                        disabled={disableReportDownload}
                        onClick={downloadTransactionsReport}
                    />
                </Actions>
            </Header>
            <TransactionsList
                isLoading={isLoading}
                data={filteredList}
                showCommissionColumn={isReseller}
            />
        </Container>
    );
}

Transactions.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Transactions.defaultProps = {
    className: undefined,
    style: undefined,
};
