import React from 'react';
import propTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
    Form,
    CreateButton,
    InfoContainer,
    InfoIcon,
    InfoText,
    InputName,
    Content,
} from './styled';
import ImportDataInput from './importDataInput';

function NewGroupForm(props) {
    const { handleSubmit, onSubmit } = props;
    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Content>
                <Field
                    component={InputName}
                    name="name"
                    label="Group Name"
                    required
                    placeholder="Enter the group name"
                    className="rainbow-m-top_large rainbow-m-bottom_large" />
                <Field component={ImportDataInput} name="contacts" />
                <CreateButton
                    type="submit"
                    label="Create"
                    variant="brand" />
            </Content>
            <InfoContainer>
                <InfoIcon />
                <InfoText>
                    We only support United States phone numbers.
                </InfoText>
            </InfoContainer>
        </Form>
    );
}

NewGroupForm.propTypes = {
    handleSubmit: propTypes.func.isRequired,
    onSubmit: propTypes.func,
};

NewGroupForm.defaultProps = {
    onSubmit: () => {},
};

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'The group name is a required field.';
    }
    if (!values.contacts) {
        errors.contacts = 'There are no contacts to import. You can import contacts (name, phone) from CSV files';
    } else {
        const { success } = values.contacts.meta;
        if (success === 0) {
            errors.contacts = 'Imported contacts are not valid United States phone numbers';
        }
    }
    return errors;
};

export default reduxForm({
    form: 'create-group',
    touchOnBlur: true,
    validate,
})(NewGroupForm);
