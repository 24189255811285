import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styled';

export default function LeftColumn({ className, value, style }) {
    return (
        <Container className={className} style={style}>
            {value}
        </Container>
    );
}

LeftColumn.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    style: PropTypes.object,
};

LeftColumn.defaultProps = {
    className: undefined,
    value: undefined,
    style: undefined,
};
