import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;
    text-align: left;
    display: flex;
    flex-shrink: 0;

    &:hover {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export default StyledLink;
