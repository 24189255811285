import React from 'react';
import PropTypes from 'prop-types';
import {
    Badge,
    Column,
} from 'react-rainbow-components';
import { useCollectionOnce } from '@rainbow-modules/firebase-hooks';
import { ColoredStatusColumn } from '@rainbow-modules/listview';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import FormattedDate from '../../../components/formatted-date-col';
import LeftColumn from '../../../components/left-column';
import {
    Container,
    MessagesTable,
    TableCard,
    Title,
} from './styled';
import Chart from './chart';
import { getFirestorePath } from '../../../helpers';

// eslint-disable-next-line react/prop-types
const TagBadge = ({ value }) => {
    if (value) {
        return <Badge className="rainbow-m-right_medium" label={value} />;
    }
    return null;
};

const statusColors = {
    failed: { backgroundColor: '#f2707a', color: '#fff' },
    delivered: { backgroundColor: '#68c4e4', color: '#fff' },
    queued: { backgroundColor: '#f4d87a', color: '#fff' },
    sent: { backgroundColor: '#cad5d5', color: '#fff' },
    received: { backgroundColor: '#71d6bb', color: '#fff' },
};

const typeColors = {
    outgoing: { backgroundColor: '#ebedf5', color: '#5c626d' },
    incoming: { backgroundColor: '#8290A0', color: '#fff' },
};

function query(ref) {
    const date = new Date();
    date.setHours(0, 0, 0, 1);
    date.setDate(date.getDate() - 15);
    return ref.where('createdAt', '>', new Date(date)).orderBy('createdAt', 'desc').limit(50);
}

function Dashboard(props) {
    const {
        className,
        style,
    } = props;

    const [messages, isLoading] = useCollectionOnce({
        path: getFirestorePath(`users/${getCurrentUser().uid}/messages`),
        query,
    });

    return (
        <Container className={className} style={style}>
            <Chart />
            <TableCard>
                <Title className="rainbow-m-left_x-large rainbow-m-top_medium">Recent Messages</Title>
                <MessagesTable keyField="id" variant="listview" isLoading={isLoading} data={messages}>
                    <Column header="Date" field="data.createdAt" width={180} component={FormattedDate} />
                    <Column
                        header="Type"
                        field="data.type"
                        width={110}
                        component={ColoredStatusColumn}
                        colors={typeColors}
                    />
                    <Column header="From" field="data.from" width={150} />
                    <Column header="To" field="data.to" width={150} />
                    <Column header="Message" field="data.body" title="data.body" component={LeftColumn} />
                    <Column header="Tags" field="data.tags" width={200} component={TagBadge} />
                    <Column
                        header="Status"
                        field="data.status"
                        width={130}
                        component={ColoredStatusColumn}
                        colors={statusColors}
                    />
                </MessagesTable>
            </TableCard>
        </Container>
    );
}

Dashboard.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Dashboard.defaultProps = {
    className: undefined,
    style: {},
};

export default Dashboard;
