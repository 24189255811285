import styled from 'styled-components';
import { Table } from 'react-rainbow-components';
import Price from '../../../components/price';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    padding: 8px 20px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 16px;
`;

export const Title = styled.h2`
    font-size: 20px;
    line-height: 1.25;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Actions = styled.div`
    display: flex;
`;

export const TransactionsTable = styled(Table)`
    box-sizing: border-box;
    height: auto;
    overflow: auto;
`;

export const StyledCommissionPrice = styled(Price)`
    align-self: center;
    align-items: flex-end;
    margin-top: 6px;
    margin-right: 8px;
`;

export const StyledPrice = styled(Price)`
    align-self: center;
    justify-content: center;
    padding: 0 20px 6px 0;
    align-items: flex-end;
    background: ${(props) => (props.value >= 0 ? '#98d38c' : '#f2707a')};
    color: white;
    height: 40px;
`;

export const StyledBadgePrice = styled(Price)`
    color: white;
    height: 30px;
    padding: 0;
    margin-top: 4px;
`;
