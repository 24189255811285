import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Plus } from '@rainbow-modules/icons';
import AddCreditForm from './components/addCreditForm';
import ButtonIconTooltip from '../button-icon-tooltip';
import { AddCreditDrawer } from './styled';

function AddCreditButton(props) {
    const { className, style } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <ButtonIconTooltip
                className={className}
                style={style}
                icon={<Plus />}
                text="Add Credit"
                variant="border-filled"
                shaded
                onClick={() => setIsOpen(true)}
            />
            <AddCreditDrawer
                header="Add Credit"
                isOpen={isOpen}
                slideFrom="left"
                onRequestClose={() => setIsOpen(false)}
            >
                <AddCreditForm onRequestClose={() => setIsOpen(false)} />
            </AddCreditDrawer>
        </>
    );
}

AddCreditButton.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

AddCreditButton.defaultProps = {
    className: undefined,
    style: {},
};

export default AddCreditButton;
