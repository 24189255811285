import React from 'react';
import PropTypes from 'prop-types';

export default function DashboardFilled(props) {
    const { className } = props;
    return (
        <svg className={className} width="26px" height="25px" viewBox="0 0 26 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="DashboardFilled" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="dashboardFilled-copy-2" transform="translate(-40.000000, -130.000000)" fill="currentColor" fillRule="nonzero">
                    <g id="Group-13" transform="translate(8.000000, 10.000000)">
                        <g id="dashboardFilled-filled" transform="translate(32.000000, 120.000000)">
                            <path d="M9.24993895,8.00006105 L1.74993895,8.00006105 C0.784973156,8.00006105 4.52970994e-14,7.21508789 4.52970994e-14,6.24993895 L4.52970994e-14,1.74993895 C4.52970994e-14,0.784973156 0.784973156,4.61852778e-14 1.74993895,4.61852778e-14 L9.24993895,4.61852778e-14 C10.2150879,4.61852778e-14 11.000061,0.784973156 11.000061,1.74993895 L11.000061,6.24993895 C11.000061,7.21508789 10.2150879,8.00006105 9.24993895,8.00006105 Z" id="Shape" />
                            <path d="M9.24993895,24 L1.74993895,24 C0.784973156,24 4.52970994e-14,23.2150268 4.52970994e-14,22.250061 L4.52970994e-14,11.750061 C4.52970994e-14,10.7849121 0.784973156,9.99993895 1.74993895,9.99993895 L9.24993895,9.99993895 C10.2150879,9.99993895 11.000061,10.7849121 11.000061,11.750061 L11.000061,22.250061 C11.000061,23.2150268 10.2150879,24 9.24993895,24 Z" id="Shape" />
                            <path d="M22.250061,24 L14.750061,24 C13.7849121,24 12.999939,23.2150268 12.999939,22.250061 L12.999939,17.750061 C12.999939,16.7849121 13.7849121,15.999939 14.750061,15.999939 L22.250061,15.999939 C23.2150268,15.999939 24,16.7849121 24,17.750061 L24,22.250061 C24,23.2150268 23.2150268,24 22.250061,24 L22.250061,24 Z" id="Shape" />
                            <path d="M22.250061,14.000061 L14.750061,14.000061 C13.7849121,14.000061 12.999939,13.2150879 12.999939,12.249939 L12.999939,1.74993895 C12.999939,0.784973156 13.7849121,4.70734562e-14 14.750061,4.70734562e-14 L22.250061,4.70734562e-14 C23.2150268,4.70734562e-14 24,0.784973156 24,1.74993895 L24,12.249939 C24,13.2150879 23.2150268,14.000061 22.250061,14.000061 L22.250061,14.000061 Z" id="Shape" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

DashboardFilled.propTypes = {
    className: PropTypes.string,
};
DashboardFilled.defaultProps = {
    className: undefined,
};
