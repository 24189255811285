import { showAppNotification } from '@rainbow-modules/app';
import { resolveError } from '../../services/firebase/resolve-errors';

export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export default function showErrorMessage(error) {
    showAppNotification({
        title: 'Error!',
        description: resolveError(error),
        icon: 'error',
        timeout: 5000,
    });
    return {
        type: SHOW_ERROR_MESSAGE,
        message: resolveError(error),
    };
}
