import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-align: left;
    margin-left: 12px;
    margin-right: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
